import { Box } from '../Box';
import styled from 'styled-components';
import * as styles from './styles';
import { GridProps } from './Grid.types';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  space,
  position,
  shadow,
  typography,
  compose,
} from 'styled-system';

const Grid = styled(Box)<GridProps>(
  () => styles.base,
  compose(
    grid,
    flexbox,
    layout,
    border,
    background,
    space,
    color,
    position,
    typography,
    shadow
  )
);

export default Grid;
