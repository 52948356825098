import React from 'react';
import { Slider } from './Slider';

interface VideoPlayerSliderProps {
  currentTime: number;
  duration: number;
  onTimeChange: (time: number) => void;
}

function formatTime(time: number): string {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
}

export function VideoPlayerSlider({
  currentTime,
  duration,
  onTimeChange,
}: VideoPlayerSliderProps) {
  return (
    <div className="w-full max-w-md mx-auto bg-black px-[10px] h-[38px]">
      <div className="flex justify-between text-sm text-white items-center gap-[10px]">
        <span>{formatTime(currentTime)}</span>
        <Slider
          min={0}
          max={duration}
          value={currentTime}
          onChange={onTimeChange}
          className="my-4 flex-1"
        />
        <span>{formatTime(duration)}</span>
      </div>
    </div>
  );
}
