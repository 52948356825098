import React, { useRef } from 'react';
import { Box, Flex, Row, Text } from 'ui';
import CalendarBase from '../ReactCalendar';
import { CurWeekBar } from './Calendar.elements';
import { WIDTH, activeTileClass, curWeekBarHeight, weekday } from './constants';
import CalendarNavigation from './CalendarNavigation';
import { calendarStyles, weekSelectStyle } from './styles';
import {
  titleContent,
  titleDisabled,
  formatShortWeekday,
  getFormattedDateNames,
  getNiceDate,
  getWeekStart,
  getStartWorkWeekOffset,
  getWeeksOffset,
} from './utils';
import { CalendarProps } from './types';

import useHandleWeekSelBar from './useHandleWeekSelBar';
import { BoxStyledProps } from 'components/layout/Box/Box.types';

export default function Calendar({
  boxShadow,
  onDateSelect,
  aboveNavText,
  maxDate = null,
  minDate = null,
  navigationPosition = 'bottom',
  calendarProps,
  maxWeek,
  minWeek,
  value,
  topDate,
  showTopDate,
  weekSelect,
  highlightWeekOffset = true,
  respectStartWorkWeek,
  freezeDay,
  showCurrWeekBar = false,
  topDateType,
  width,
  navHeight,
  navType,
  ...rest
}: CalendarProps & BoxStyledProps) {
  const curWeekBarRef = useRef<HTMLDivElement>(null);
  const calendarWrapperRef = useRef<HTMLDivElement>(null);
  const isBottom = navigationPosition === 'bottom';
  const isTop = navigationPosition === 'top';

  const { year } = getFormattedDateNames(value);

  useHandleWeekSelBar({
    activeTileClass,
    calendarWrapperRef,
    curWeekBarRef,
  });

  const nav = (
    <CalendarNavigation
      position={navigationPosition}
      weekSelect={weekSelect}
      value={value}
      withCalendarIcon={isBottom ? true : false}
      onDateSelect={onDateSelect}
      topDateType={topDateType}
      height={navHeight}
      navType={navType}
    />
  );

  return (
    <Box
      bg="white"
      // pt="21px"
      boxShadow={boxShadow}
      display="grid"
      gridAutoRows="max-content 1fr"
      justifyContent="center"
      css={[calendarStyles, weekSelect && weekSelectStyle]}
      {...rest}
      width={width || WIDTH}
      px="0"
    >
      {showTopDate && (
        <Flex pb="1rem" flexCenter>
          <Text fontSize="16px" fontWeight={500}>
            {topDate ?? `${getNiceDate(value)}, ${year}`}
          </Text>
        </Flex>
      )}
      <Row height="100%">
        {isTop && aboveNavText && (
          <Text className="aboveTextNav">{aboveNavText}</Text>
        )}
        {isTop ? nav : null}
        <Box
          width={WIDTH}
          position="relative"
          ref={calendarWrapperRef}
          height="100%"
          mt={isTop ? '12px' : ''}
        >
          <Flex alignItems="center" className="weekNumberIndex">
            <Text fontSize="10px">#</Text>
          </Flex>
          {showCurrWeekBar && (
            <CurWeekBar ref={curWeekBarRef} height={curWeekBarHeight} />
          )}

          {/* Note:in order to sync week days with with custom weekStart, calendar type Iso is overrided by custom customWeekStartDay,  */}
          {/* if any further changes needed */}
          {/*  */}
          <CalendarBase
            customWeekStartDay={getStartWorkWeekOffset()}
            customWeeksOffset={getWeeksOffset()}
            calendarType={getWeekStart() === 0 ? 'US' : 'ISO 8601'}
            formatShortWeekday={formatShortWeekday(weekday)}
            tileContent={titleContent({ weekSelect, highlightWeekOffset })}
            key={value.valueOf()}
            showNavigation={false}
            value={value}
            showWeekNumbers
            onClickDay={onDateSelect}
            maxDate={maxDate}
            minDate={minDate}
            tileDisabled={titleDisabled({ maxWeek, minWeek })}
            {...calendarProps}
          />
        </Box>
        {isBottom && aboveNavText && (
          <Text className="aboveTextNav">{aboveNavText}</Text>
        )}
        {isBottom ? nav : null}
      </Row>
    </Box>
  );
}
