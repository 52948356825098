import { css } from 'styled-components';
import colors from 'theme/colors';
import { Row, Box, Button, Flex } from 'ui';
import TryAgainPopup from './TryAgainPopup';
import theme from 'theme';

interface RecordButtonProps {
  isRecording: boolean;
  isRecordReady: boolean;
  onNextStep: () => void;
  onRecordStart: () => void;
  onRecordStop: () => void;
  onHideRetryConfirm: () => void;
  onShowRetryConfirm: () => void;
  isShowRetryConfirm: boolean;
  timeLeft: number;
  children?: React.ReactNode;
  onRetryRecord?: () => void;
  isEng?: boolean;
}

export default function RecordButton({
  isRecordReady,
  isRecording,
  onNextStep,
  onRecordStart,
  onRecordStop,
  isShowRetryConfirm,
  onShowRetryConfirm,
  onHideRetryConfirm,
  timeLeft,
  children,
  onRetryRecord,
  isEng,
}: RecordButtonProps) {
  const isTryAgain = !isRecording && isRecordReady;

  return (
    <>
      <Row
        mt="20px"
        width="100%"
        position="absolute"
        backgroundColor="transparent"
        flexCenter
        height="140px"
        borderTopLeftRadius="20px"
        borderTopRightRadius="20px"
        bottom="0"
        zIndex={100}
      >
        <Box
          position="absolute"
          top="-25px"
          borderRadius="100%"
          size="90px"
          borderWidth="4px"
          borderStyle="solid"
          borderColor="white"
          flexCenter
          style={{
            cursor: 'pointer',
            ...(isRecording && {
              backgroundColor: colors.redCrimson,
            }),
            ...(!isRecording && {
              backgroundColor: colors.greenSummer,
            }),
            ...(isTryAgain && {
              backgroundColor: colors.blueDeep,
            }),
          }}
        >
          <Box
            textAlign="center"
            fontWeight="700"
            color="white"
            fontSize="16px"
            margin="0 auto"
            height="100%"
            css={css`
              & {
                display: -webkit-flex;
                display: flex;
                justify-content: center;
                -webkit-justify-content: center;
                align-items: center;
                -webkit-align-items: center;
              }
            `}
            {...{
              ...(isRecording && {
                onClick: onRecordStop,
              }),
              ...(!isRecording && {
                onClick: onRecordStart,
              }),
              ...(isTryAgain && {
                onClick: () => {
                  onShowRetryConfirm();
                },
              }),
            }}
          >
            <span
              key={timeLeft}
              className="buttonTextWrapper"
              style={{
                whiteSpace: 'wrap',
                padding: '10px',
                textAlign: 'center',
              }}
            >
              {isRecording && (
                <span key={timeLeft}>
                  {isEng ? 'STOP' : 'DETENER'}
                  <br />
                  <span>
                    {timeLeft} {isEng ? 'SEC' : 'SEG'}
                  </span>
                </span>
              )}
              {!isRecordReady && !isRecording && (
                <>{isEng ? 'START RECORD' : 'GRABAR AHORA'}</>
              )}
              {isRecordReady && !isRecording && (
                <Flex gap="0" flexDirection="column">
                  <span
                    style={{
                      height: '16px',
                    }}
                  >
                    {isEng ? ' TRY' : 'INTENTAR'}
                  </span>
                  <span>{isEng ? ' AGAIN' : 'OTRA VEZ'}</span>
                </Flex>
              )}
            </span>
          </Box>
        </Box>
        <Button
          mt="60px"
          maxWidth="332px"
          kind="large"
          disabled={!isRecordReady}
          onClick={onNextStep}
          css={
            !isRecordReady
              ? `& { background-color: ${theme.colors.grayDisabled} !important; }`
              : ''
          }
        >
          {children}
        </Button>
      </Row>
      <TryAgainPopup
        isEng={isEng}
        onHideRetryConfirm={onHideRetryConfirm}
        isShowRetryConfirm={isShowRetryConfirm}
        onRecordStart={() => {
          onRetryRecord?.();
        }}
      />
    </>
  );
}
