import React from 'react';
import { Grid, Col, Flex, Row, Box, Text } from 'ui';
import { showTwoDigits } from '../utils';
import { useIsMobile } from 'hooks/useBreakPoint';

interface Props {
  width?: string;
  height?: string;
  delta?: number;
  action: (val: number) => void;
  type: 'hours' | 'minutes';
  setActiveTab: (activeTab: string) => void;
  minutesDelta?: number;
  hideInnerBorder?: boolean;
}

export default function TimePickerDateView({
  width,
  height,
  action,
  setActiveTab,
  minutesDelta,
  type = 'hours',
  hideInnerBorder,
}: Props) {
  const isMobile = useIsMobile();
  const handleClick = (val: number) => {
    action(val);
    setActiveTab('1');
  };

  const delta = minutesDelta ? minutesDelta : type === 'hours' ? 1 : 15;
  const quantity = minutesDelta ? 60 / minutesDelta : type === 'hours' ? 13 : 4;
  // note: maybe add column props in the future
  const col = minutesDelta ? 4 : type === 'hours' ? 4 : 2;

  return (
    <Col
      width={isMobile ? 'auto' : width}
      height={isMobile ? 'auto' : height}
      border={!isMobile && !hideInnerBorder ? '1px solid #F4F2FF' : ''}
      borderRadius="10px"
    >
      <Col
        gridAutoFlow="row"
        gridTemplateColumns={`repeat(${col}, 40px)`}
        justifyContent="center"
        margin="auto"
        gap={isMobile ? '20px 13px' : ''}
      >
        {new Array(quantity).fill('').map((_, i) => {
          if (type === 'hours' && i === 0) return null;
          const val = i * delta === 0 ? '00' : `${i * delta}`;
          return (
            <Flex
              width={isMobile ? 'auto' : '34px'}
              height={isMobile ? 'auto' : '34px'}
              alignItems="center"
              justifyContent="center"
              borderRadius="5px"
              key={`time-picker-view-${i}`}
              onClick={() => handleClick(Number(val))}
              css={`
                transition: 0.1s;
                cursor: pointer;
                &:hover {
                  background-color: #f4f2ff;
                }
              `}
            >
              <Text
                fontWeight="400"
                color="#569DE5"
                fontSize={isMobile ? '24px' : '16px'}
              >
                {showTwoDigits(Number(val))}
              </Text>
            </Flex>
          );
        })}
      </Col>
    </Col>
  );
}
