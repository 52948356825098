'use client';

import useVideoControl from '@/hooks/useVideoControl';
import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { Button, ButtonProps } from 'ui';

interface ButtonFillEffectProps extends ButtonProps {
  children?: React.ReactNode;
  videoRef: HTMLVideoElement | null;
}

export default function ButtonFillEffect({
  children,
  videoRef,
  ...props
}: ButtonFillEffectProps) {
  const { curTime, isPlaying, duration } = useVideoControl(videoRef);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const [curWidth, setCurWidth] = React.useState(0);
  const curAnimationId = React.useRef<number | null>(null);

  const handleClearAnimation = useCallback(() => {
    if (curAnimationId.current) {
      cancelAnimationFrame(curAnimationId.current);
      curAnimationId.current = null;
    }
  }, []);

  useEffect(() => {
    if (buttonRef.current) {
      setCurWidth(buttonRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (!isPlaying) {
      handleClearAnimation();
      return;
    }
  });

  return (
    <Button
      ref={buttonRef}
      overflow="hidden"
      position="relative"
      maxWidth="332px"
      width="100%"
      kind="large"
      {...props}
    >
      <div
        style={{
          position: 'relative',
          zIndex: 2,
        }}
      >
        {children}
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: Math.min((curTime / duration) * curWidth, curWidth) + 'px',
          width: '100%',
          height: '100%',
          backgroundColor: 'lightblue',
          transition: 'left 0.5s',
          opacity: 0.5,
          zIndex: 1,
        }}
      ></div>
    </Button>
  );
}
