import { initValues, questionsAnswersContext } from './constants';
import { QuestionsAnswersContextType } from './types';
import { useQuestionsAnswersLogic } from './useQuestionsAnswersLogic';

const QuestionsAnswersProvider = (props: {
  children: React.ReactNode;
  initValues?: Partial<QuestionsAnswersContextType>;
}) => {
  const values = useQuestionsAnswersLogic({
    ...props.initValues,
    ...initValues,
  });
  return (
    <questionsAnswersContext.Provider value={values}>
      {props.children}
    </questionsAnswersContext.Provider>
  );
};

export { QuestionsAnswersProvider };
