import { css } from 'styled-components';
import { ColProps } from './Col.types';

export const base = ({ maxContent, single }: ColProps) => css`
  grid-auto-flow: column;
  ${single &&
  css`
    grid-auto-columns: 100%;
  `}
  ${maxContent &&
  css`
    grid-auto-columns: max-content;
  `}
`;
