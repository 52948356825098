import React from 'react';
import { layout, LayoutProps } from 'styled-system';
import { TextAreaProps } from 'antd/es/input';
import Input, { inputStyles } from 'components/Input';
import styled, { css } from 'styled-components';
import { Flex, Text } from 'ui';
import { useIsMobile } from 'hooks/useBreakPoint';

interface BaseProps {
  label?: string;
  required?: boolean;
}

type Props = BaseProps & TextAreaProps & LayoutProps & { resize?: boolean };

const TextAreaStyled = styled(Input.TextArea)<{ resize?: boolean }>(
  ({ resize }) => css`
    ${inputStyles} ${layout};
    // resize: ${resize ? '' : 'none'} !important;
    resize: none !important;
    height: 84px !important;
  `
);

export const TextArea = ({
  value,
  label,
  name,
  required,
  ...restProps
}: Props) => {
  const isMobile = useIsMobile();

  return (
    <Flex flexDirection="column">
      {label ? (
        <Flex>
          <Text fontSize={isMobile ? '16px' : '14px'} fontWeight="500" mb="6px">
            {label}
          </Text>
          {required ? (
            <Text color="#EB5757" ml="2px">
              *
            </Text>
          ) : null}
        </Flex>
      ) : null}
      <TextAreaStyled name={name} value={value} {...restProps} />
    </Flex>
  );
};

export default TextArea;
