import { createGlobalStyle, css } from 'styled-components';

const AppGlobalStyle = createGlobalStyle(
  ({ theme }) => css`
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    body {
      overflow-x: auto;
      height: unset !important;
      min-height: 100dvh !important;
    }

    html {
      overflow-x: auto;
      min-height: 100dvh !important;
      height: unset !important;
      overflow-x: unset !important;
    }

    #root {
      color: ${theme.colors.textDarkBlue};
    }
  `
);

export default AppGlobalStyle;
