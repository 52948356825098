import useVideoControl from '@/hooks/useVideoControl';
import React from 'react';
import { PlayCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import Preloader from '../Preloader';
import { VideoPlayerSlider } from './VideoPlayerSlider';
import { Flex } from 'ui';

interface VideoPlayerProps {
  src?: string;
  onSetCurRef?: (ref: HTMLVideoElement | null) => void;
  autoPlay?: boolean;
  style?: React.CSSProperties;
  PlayIcon?: React.ReactNode;
  ReplayIcon?: React.ReactNode;
  width?: string;
  height?: string;
  type?: 'video/webm' | 'video/mp4';
  controls?: boolean;
  blurred?: boolean;
  children?: React.ReactNode;
}

const VideoPlayer = React.forwardRef<HTMLVideoElement, VideoPlayerProps>(
  (
    {
      src,
      onSetCurRef,
      autoPlay,
      style,
      PlayIcon,
      ReplayIcon,
      width,
      height,
      controls,
      children,
    }: VideoPlayerProps,
    ref
  ) => {
    const {
      isPlaying,
      play,
      setCurRef,
      isReady,
      isWatched,
      curTime,
      duration,
      curRef,
    } = useVideoControl();
    const isRepeat = curTime >= duration && isWatched;

    return (
      <div
        style={{
          width: width ?? '330px',
          height: height ?? undefined,
          overflow: 'hidden',
          position: 'relative',
          ...style,
        }}
      >
        <video
          onLoadedMetadata={(e) => {
            const isSafari = /^((?!chrome|android).)*safari/i.test(
              navigator.userAgent
            );

            if (isSafari) {
              const video = e.target as HTMLVideoElement;
              video.currentTime = 0.1;
            }
          }}
          preload="metadata"
          onClick={play}
          playsInline
          autoPlay={autoPlay}
          ref={(node) => {
            setCurRef(node);
            onSetCurRef?.(node);
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          }}
          style={{
            position: 'relative',
          }}
          src={src}
          width="100%"
          height="100%"
        >
          {children}
        </video>
        {!isReady && (
          <div
            style={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Preloader />
          </div>
        )}
        {controls && (
          <Flex width="100%">
            <VideoPlayerSlider
              currentTime={curTime}
              duration={duration}
              onTimeChange={(time) => {
                if (curRef) {
                  curRef.currentTime = time;
                }
              }}
            />
          </Flex>
        )}
        <div
          style={{
            pointerEvents: 'none',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            zIndex: 3,
          }}
        >
          {isReady &&
            !isRepeat &&
            !isPlaying &&
            (PlayIcon || (
              <PlayCircleOutlined
                style={{
                  pointerEvents: 'none',
                  cursor: 'pointer',
                  fontSize: '40px',
                  color: 'white',
                }}
              />
            ))}
          {isReady &&
            isRepeat &&
            !isPlaying &&
            (ReplayIcon || (
              <ReloadOutlined
                style={{
                  pointerEvents: 'none',
                  cursor: 'pointer',
                  fontSize: '40px',
                  color: 'white',
                }}
              />
            ))}
        </div>
      </div>
    );
  }
);

export default VideoPlayer;
