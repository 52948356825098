import 'styled-components';
import { Col, Row, Text } from 'ui';
import { Logo } from 'assets';
import { FlagSpain, FlagUsa } from 'assets/index';
import { useNavigate } from 'react-router';
import LayoutPageMobile from '@/components/LayoutPageMobile';
import { useGetStatus } from '@/api/services';
import { useQuestionsAnswers } from '@/contexts/QuestionsAnswersContext';
import { Language } from '@/api/typesApi';

function Welcome() {
  const { onSetLanguage, invitationKey } = useQuestionsAnswers();
  const { data } = useGetStatus({
    invitation_key: invitationKey,
  });

  const isEngAvailable = data?.english_intro;
  const isSpanishAvailable = data?.spanish_intro;
  const name = data?.lead_name;

  const navigate = useNavigate();

  const handleNext = (language: Language) => {
    onSetLanguage(language);
    navigate('/tutorial');
  };

  return (
    <LayoutPageMobile pb="20px">
      <Row flexCenter>
        <Logo />
        <Text fontWeight="900" mt="30px" fontSize="20px" upper>
          Hello {name}!
        </Text>
        <Row flexCenter mt="60px">
          <Text fontWeight="900" fontSize="20px" upper>
            Welcome to
          </Text>
        </Row>
        <Row flexCenter mt="10px">
          <Text fontWeight="800" fontSize="23px" upper>
            123Jobs.com
          </Text>
        </Row>
        <Text
          fontWeight="800"
          fontSize="18px"
          maxWidth="220px"
          upper
          center
          mt="60px"
        >
          Please select interview language
        </Text>

        <Col gap="60px" mt="80px">
          {isEngAvailable && (
            <Row
              gap="20px"
              flexCenter
              css="cursor: pointer;"
              onClick={() => handleNext('english')}
            >
              <FlagUsa />
              <Text fontWeight="800" fontSize="18px" upper>
                English
              </Text>
            </Row>
          )}
          {isSpanishAvailable && (
            <Row
              gap="20px"
              flexCenter
              onClick={() => handleNext('spanish')}
              css="cursor: pointer;"
            >
              <FlagSpain />
              <Text fontWeight="800" fontSize="18px" upper>
                Español
              </Text>
            </Row>
          )}
        </Col>
      </Row>
    </LayoutPageMobile>
  );
}

export default Welcome;
