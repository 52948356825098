import 'styled-components';
import { Row, Text } from 'ui';
import { FinishEnvelop, Logo } from 'assets/index';
import LayoutPageMobile from '@/components/LayoutPageMobile';
import { useQuestionsAnswers } from '@/contexts/QuestionsAnswersContext';

function Finish() {
  const { selectedLanguage } = useQuestionsAnswers();
  const isEng = selectedLanguage === 'english';
  return (
    <LayoutPageMobile>
      <Row alignItems="flex-start" justifyItems="center" px="20px">
        <Logo />
        <Row flexCenter mt="70px">
          <Text fontWeight="900" mt="" fontSize="20px" upper>
            {isEng ? 'SUCCESS!' : 'PERFECTO!'}
          </Text>
        </Row>
        <Row flexCenter mt="20px">
          <Text center fontWeight="900" fontSize="20px" upper maxWidth="300px">
            {isEng
              ? 'You interview IS COMPLETE!'
              : 'TU ENTREVISTA HA SIDO COMPLETADA!'}
          </Text>
        </Row>
        <Row flexCenter mt="40px">
          <FinishEnvelop />
        </Row>
        <Row flexCenter mt="40px">
          <Text center fontWeight="900" fontSize="18px" upper>
            {isEng
              ? 'WE WILL REVIEW YOUR INTERVIEW TO DETERMINE THE NEXT STEPS FOR YOUR APPLICATION'
              : 'REVISAREMOS LA ENTREVISTA PARA DETERMINAR LOS SIGUIENTES PASOS DE TU APLICACIÓN.'}
          </Text>
        </Row>
        <Row flexCenter mt="40px">
          <Text center fontWeight="900" fontSize="20px" upper>
            {isEng ? 'THANK YOU!' : 'GRACIAS!'}
          </Text>
        </Row>
      </Row>
    </LayoutPageMobile>
  );
}

export default Finish;
