import { ConfigProvider } from 'antd';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme, { antdColorsConfig, antdTypographyConfig } from 'theme';
import Apps from './App';

export default function AppContext({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            ...antdColorsConfig,
            ...antdTypographyConfig,
          },
        }}
      >
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ConfigProvider>
    </>
  );
}
