import React, { forwardRef, useRef, useState } from 'react';
import { Flex, Input, Text } from 'ui';
import DatePickerBase from 'react-datepicker';
// @ts-ignore
import { CalendarArrowLeft, CalendarArrowRight } from 'assets/svgComponents';
import { format } from 'date-fns';
import { getStartWorkWeekOffset } from 'components/Calendar';

const CustomInput = forwardRef((props: any, ref) => {
  return <Input ref={ref} height="40px" borderColor="#7F87B6" {...props} />;
});

export default function DatePicker({
  name,
  value,
  label,
  maxDate,
  required,
  onChange,
  onFocus,
  onBlur,
}: any) {
  const inputRef = useRef(null);

  return (
    // todo shared Input Label component
    <Flex flexDirection="column">
      {label ? (
        <Flex>
          <Text fontSize="14px" fontWeight="500" mb="6px">
            {label}
          </Text>
          {required ? (
            <Text color="#EB5757" ml="2px">
              *
            </Text>
          ) : null}
        </Flex>
      ) : null}

      <DatePickerBase
        name={name}
        value={value}
        selected={value}
        onChange={onChange}
        placeholder=""
        suffixIcon={null}
        prevIcon={<CalendarArrowLeft />}
        nextIcon={<CalendarArrowRight />}
        calendarClassName="date-picker-calendar"
        calendarStartDay={getStartWorkWeekOffset()}
        maxDate={maxDate}
        customInput={<CustomInput inputRef={inputRef} />}
      />
    </Flex>
  );
}
