import 'styled-components';
import { Row } from 'ui';
import VideoPlayer from '@/components/VideoPlayer';
import useVideoControl from '@/hooks/useVideoControl';
import ButtonFillEffect from '@/components/ButtonFillEffect';
import LayoutPageMobile from '@/components/LayoutPageMobile';
import TopTextBlock from '@/components/TopTextBlock';
import { isLocal } from '@/utils';
import useGetQuestionWithAnswered from '@/hooks/useGetQuestionWithAnswered';
import Introduction from './Introduction';

export default function Questions() {
  const { setCurRef, curRef, isWatched } = useVideoControl();

  const {
    questionName,
    questionText,
    questionVideo,
    isEng,
    isError,
    isLoading,
    error,
    handleNextStep,
    questionNumber,
  } = useGetQuestionWithAnswered();

  if (questionNumber === 0) {
    return (
      <Introduction
        error={error}
        isError={isError}
        handleNextStep={handleNextStep}
        isLoading={isLoading}
        isEng={isEng}
        questionVideo={questionVideo}
      />
    );
  }

  return (
    <LayoutPageMobile
      loading={isLoading}
      error={!!isError}
      errorInfo={(error as Error)?.message ?? error}
      header={
        <Row flexCenter>
          <TopTextBlock
            title={(isEng ? 'Question #' : 'Pregunta #') + questionName}
            content={questionText?.split(', ') || []}
          />
        </Row>
      }
      footer={
        <Row
          py="20px"
          borderTopLeftRadius="4px"
          borderTopRightRadius="4px"
          width="100%"
          flexCenter
          backgroundColor="white"
        >
          <ButtonFillEffect
            videoRef={curRef}
            onClick={() => {
              if (isLocal || isWatched) {
                handleNextStep();
                return;
              }
            }}
          >
            {isEng ? 'Answer Question' : 'RESPONDER PREGUNTA'}
          </ButtonFillEffect>
        </Row>
      }
    >
      <Row flexCenter>
        <Row mt="20px">
          <VideoPlayer autoPlay src={questionVideo} onSetCurRef={setCurRef} />
        </Row>
      </Row>
    </LayoutPageMobile>
  );
}
