import React, { useState, useEffect, useRef, useCallback } from 'react';

interface SliderProps {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
  className?: string;
}

export function Slider({
  min,
  max,
  value,
  onChange,
  className = '',
}: SliderProps) {
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef<HTMLDivElement>(null);

  const getPercentage = (current: number, min: number, max: number) => {
    return ((current - min) / (max - min)) * 100;
  };

  const handleTouchDown = () => {
    setIsDragging(true);
  };

  const handleTouchMove = useCallback(
    (event: TouchEvent) => {
      if (!isDragging || !sliderRef.current) return;
      const rect = sliderRef.current.getBoundingClientRect();
      const touch = event.touches[0];
      const percentage = (touch.clientX - rect.left) / rect.width;
      const newValue = Math.round(percentage * (max - min) + min);
      onChange(Math.max(min, Math.min(max, newValue)));
    },
    [isDragging, max, min, onChange]
  );

  const handleTouchUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchUp);
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchUp);
    };
  }, [handleTouchMove]);

  return (
    <div
      ref={sliderRef}
      className={`relative h-[10px] bg-white/30 rounded-full cursor-pointer text-[16px]  ${className}`}
      onTouchStart={handleTouchDown}
    >
      <div
        className="absolute h-full bg-white rounded-full "
        style={{ width: `${getPercentage(value, min, max)}%` }}
      />
      <div
        className="absolute w-[23px] h-[23px] bg-white border-0 rounded-full -mt-[6px] -ml-2"
        style={{ left: `${getPercentage(value, min, max)}%` }}
      />
    </div>
  );
}
