import styled, { css } from 'styled-components';
import * as styles from './styles';
import { TextBaseProps } from './Text.types';

export const TextBase = styled('span')<TextBaseProps>(
  styles.basic,
  styles.kindIcon,
  styles.styledSystem,

  ({ noWrap }) =>
    noWrap &&
    css`
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: keep-all;
      white-space: nowrap;
    `,
  ({ center }) =>
    center &&
    css`
      text-align: center;
    `,
  ({ upper }) =>
    upper &&
    css`
      text-transform: uppercase;
    `
);

export const LinkBase = styled('a')(
  styles.basic,
  styles.kindIcon,
  styles.styledSystem
);
