import { useState } from 'react';
import {
  CUR_QUESTION_NUM,
  initValues,
  INVITATION_KEY,
  SELECTED_LANGUAGE,
} from './constants';
import { QuestionsAnswersContextType } from './types';
import { Language } from '@/api/typesApi';

export const useQuestionsAnswersLogic = (
  values: Partial<QuestionsAnswersContextType> = {}
): QuestionsAnswersContextType => {
  const actualValues = { ...initValues, ...values };

  /// Set current question number
  const prevSavedQuestionNumber = Number(
    sessionStorage.getItem(CUR_QUESTION_NUM)
  );
  const [questionNumber, setQuestionNumber] = useState<number>(
    prevSavedQuestionNumber > 0
      ? prevSavedQuestionNumber
      : actualValues.questionNumber
  );

  /// Set total questions
  const [totalQuestions, setTotalQuestions] = useState<number>(
    actualValues.totalQuestions
  );

  /// Set selected language
  const preSavedSelectedLanguage = localStorage.getItem(
    SELECTED_LANGUAGE
  ) as Language;
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    preSavedSelectedLanguage ?? actualValues.selectedLanguage
  );

  /// Set invitation key
  const preSavedInvitationKey = localStorage.getItem(INVITATION_KEY);
  const [invitationKey, setInvitationKey] = useState<string>(
    preSavedInvitationKey || ''
  );

  const onReset = () => {
    setQuestionNumber(0);
    setTotalQuestions(3);
    setSelectedLanguage('english');
  };

  const onSetLanguage = (language: Language) => {
    localStorage.setItem(SELECTED_LANGUAGE, language);
    setSelectedLanguage(language);
  };

  const onSetTotalQuestions = (total: number) => {
    setTotalQuestions(total);
  };

  const onSetInvitationKey = (key: string) => {
    if (key !== preSavedInvitationKey) {
      onReset();
    }

    localStorage.setItem(INVITATION_KEY, key);
    setInvitationKey(key);
  };

  const onNextQuestion = () => {
    if (questionNumber < totalQuestions - 1) {
      setQuestionNumber((prev) => {
        const newQuestionNumber = prev + 1;
        sessionStorage.setItem(CUR_QUESTION_NUM, newQuestionNumber.toString());
        return newQuestionNumber;
      });
    }
  };

  console.table({
    questionNumber,
    totalQuestions,
    selectedLanguage,
    invitationKey,
  });

  return {
    questionNumber,
    totalQuestions,
    onNextQuestion,
    selectedLanguage,
    onSetLanguage,
    onSetTotalQuestions,
    invitationKey,
    onSetInvitationKey,
  };
};
