import { post } from './fetchWrapper';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  InterviewPreviewOutput,
  QuestionsAnswersInput,
  QuestionsOutput,
  StatusInput,
  StatusOutput,
} from './typesApi';
import { endpoints } from '@/constants';
import { mockApi } from './mockApi';
import { isLocal } from '@/utils';

export const api = isLocal
  ? mockApi
  : {
      getStatus: (input: StatusInput) =>
        post<StatusOutput, StatusInput>(endpoints.status, input),
      getQuestions: (input: QuestionsAnswersInput) =>
        post<QuestionsOutput, QuestionsAnswersInput>(
          endpoints.questions,
          input
        ),
      postAnswersConfirmation: (input: QuestionsAnswersInput) =>
        post<boolean, QuestionsAnswersInput>(
          endpoints.answersConfirmation,
          input
        ),
      postInterviewPreview: (input: StatusInput) =>
        post<InterviewPreviewOutput, StatusInput>(
          endpoints.interviewPreview,
          input
        ),
    };

export const useGetStatus = (input: StatusInput, skip?: boolean) => {
  return useQuery({
    queryKey: ['status'],
    queryFn: async () => {
      const resp = await api.getStatus(input);
      return resp;
    },
    enabled: !!input?.invitation_key && !skip,
  });
};

export const useGetQuestions = (input: QuestionsAnswersInput) => {
  return useQuery({
    queryKey: ['questions'],
    queryFn: async () => {
      return await api.getQuestions(input);
    },
  });
};

export const useGetQuestionsMutation = () => {
  return useMutation({
    mutationFn: async (input: QuestionsAnswersInput) => {
      const resp = await api.getQuestions(input);
      return resp;
    },
  });
};

export const usePostAnswersConfirmation = () => {
  return useMutation({
    mutationFn: async (input: QuestionsAnswersInput) => {
      const resp = await api.postAnswersConfirmation(input);
      return resp;
    },
  });
};

export const useInterviewPreview = (input: StatusInput) => {
  return useQuery({
    enabled: !!input?.invitation_key,
    queryKey: ['interviewPreview'],
    queryFn: async () => {
      const resp = await api.postInterviewPreview(input);
      return resp;
    },
  });
};
