import React, { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';
import { ButtonProps } from './Button.types';
import { Spin } from 'antd';
import { ButtonBase } from './Button.ui';
import { css } from 'styled-components';
import { Flex } from 'ui';
import { useIsMobile } from 'hooks/useBreakPoint';

export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(
  (
    {
      loading,
      disabled,
      type = 'button',
      shape = 'default',
      onClick = undefined,
      testid,
      children,
      variant = 'primary',
      kind = 'normal',
      ...restProps
    },
    ref
  ) => {
    const isMobile = useIsMobile();
    const isClickable = !loading && !disabled;

    return (
      <ButtonBase
        disabled={disabled}
        data-testid={testid}
        onMouseUp={(e) => {
          !isMobile && isClickable && onClick && onClick(e);
        }}
        onTouchEnd={(e) => {
          isMobile && isClickable && onClick && onClick(e as any);
          e.preventDefault();
        }}
        type={type}
        kind={kind}
        variant={variant}
        shape={shape}
        ref={ref}
        {...restProps}
      >
        {loading && (
          <Flex mr="10px">
            <Spin
              tip={null}
              css={css`
                display: flex;
                span i.ant-spin-dot-item {
                  background-color: white;
                }
              `}
            />
          </Flex>
        )}
        {children}
      </ButtonBase>
    );
  }
);

Button.displayName = 'Button';

export default Button;
