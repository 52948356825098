import 'styled-components';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Welcome from '../../pages/Welcome';
import Tutorial from '../../pages/Tutorial';
import Questions from '../../pages/Questions';
import Answers from '../../pages/Answers';
import AppGlobalStyle from '../../../../frontendReact/src/containers/App/AppGlobalStyle';
import AppGlobalStyleOverride from './AppGlobalStyle';
import theme from 'theme';
import Finish from '@/pages/Finish';
import { useEffect } from 'react';
import { useGetStatus } from '@/api/services';
import { DEBUG, routes } from '@/constants';
import { useQuestionsAnswers } from '@/contexts/QuestionsAnswersContext';
import {
  INVITATION_KEY,
  TURN_OFF_HANDLE_ROUTING,
} from '@/contexts/QuestionsAnswersContext/constants';
import ContainerFullPage from '@/components/ContainerFullPage';
import PreloaderFullPage from '@/components/PreloaderFullPage';
import InterviewPreview from '@/pages/InterviewPreview';
import './App.css';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { pathname, search } = useLocation();

  const navigate = useNavigate();
  const {
    invitationKey: savedInvitationKey,
    onSetInvitationKey,
    onSetTotalQuestions,
    selectedLanguage,
  } = useQuestionsAnswers();

  const params = new URLSearchParams(search);
  const invitationKey = params?.get?.(INVITATION_KEY);
  const debug = !!params?.get?.(DEBUG);
  const turnOffHandleRouting = !!params?.get?.(TURN_OFF_HANDLE_ROUTING);

  // [DEBUG] bypass token
  // const isNoToken = false;
  const isNoToken = !savedInvitationKey && !invitationKey;

  if (invitationKey) {
    onSetInvitationKey(invitationKey);
  }

  const { data, isLoading, isError, error } = useGetStatus(
    {
      invitation_key: invitationKey || savedInvitationKey,
    },
    isNoToken
  );

  /// Set total questions
  useEffect(() => {
    const totalQuestions = selectedLanguage
      ? data?.questions_total_english
      : data?.questions_total_spanish;

    if (totalQuestions) {
      onSetTotalQuestions(totalQuestions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (turnOffHandleRouting) {
      return;
    }

    const isTutorial = data?.status === 'start';
    const isQuestions = data?.status === 'in_progress';
    const isFinish = data?.status === 'finished';

    const isIntreviewPreviewRoute = [routes.interviewPreview].includes(
      pathname
    );

    const isTutotialRoutes = [
      routes.root,
      routes.welcome,
      routes.tutorial,
    ].includes(pathname);

    const isQuestionsRoutes = [routes.questions, routes.answers].includes(
      pathname
    );

    const isFinishRoutes = [routes.finish].includes(pathname);

    if (isIntreviewPreviewRoute) {
      return;
    }

    if (isTutorial && !isTutotialRoutes) {
      navigate(routes.tutorial);
    }

    if (isQuestions && !isQuestionsRoutes) {
      navigate(routes.questions);
    }

    if (isFinish && !isFinishRoutes) {
      navigate(routes.finish);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.status, turnOffHandleRouting]);

  const stylesRender = (
    <>
      <AppGlobalStyle theme={theme} />
      <AppGlobalStyleOverride theme={theme} />
    </>
  );

  // [DEBUG] Error boundary
  // throw new Error(
  //   'Uncaught error: Error: Something went wrong. Please refresh the page and try again'
  // );

  if (!isNoToken && isLoading) {
    return (
      <>
        {stylesRender}
        <PreloaderFullPage />
      </>
    );
  }

  if (isNoToken || isError || !data?.status) {
    return (
      <>
        {stylesRender}
        <ContainerFullPage>
          Please provide an valid invitation key
          {debug && (
            <pre>
              <code>{isError ? JSON.stringify(error) : null}</code>
            </pre>
          )}
        </ContainerFullPage>
      </>
    );
  }

  return (
    <>
      {stylesRender}
      <ScrollToTop />
      <Routes>
        <Route path={routes.root} element={<Welcome />} />
        <Route path={routes.tutorial} element={<Tutorial />} />
        <Route path={routes.questions} element={<Questions />} />
        <Route path={routes.answers} element={<Answers />} />
        <Route path={routes.finish} element={<Finish />} />
        <Route path={routes.interviewPreview} element={<InterviewPreview />} />
      </Routes>
    </>
  );
}

export default App;
