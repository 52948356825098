import ContainerFullPage from '../ContainerFullPage';
import Preloader from '../Preloader';

export default function PreloaderFullPage() {
  return (
    <ContainerFullPage>
      <Preloader />
    </ContainerFullPage>
  );
}
