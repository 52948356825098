import { useCallback, useEffect, useState } from 'react';

interface VideoRecord {
  isEng: boolean;
  mediaRecordRef: React.MutableRefObject<MediaRecorder | null>;
  setIsRecordReady: React.Dispatch<React.SetStateAction<boolean>>;
  setVideoRecordDataWebm: React.Dispatch<
    React.SetStateAction<Blob | undefined>
  >;
  cameraWidth: number;
  setRecordReplaySrc: React.Dispatch<React.SetStateAction<string | null>>;
}

export default function VideoRecord({
  mediaRecordRef,
  setIsRecordReady,
  setVideoRecordDataWebm,
  cameraWidth,
  setRecordReplaySrc,
}: VideoRecord) {
  const [videoPreview, setVideoPreview] = useState<HTMLVideoElement | null>(
    null
  );

  const handleClearCameraConnection = useCallback(async () => {
    if (videoPreview && videoPreview.srcObject) {
      const stream = videoPreview.srcObject as MediaStream;
      const tracks = stream.getTracks();
      for (let i = 0; i < tracks.length; i++) {
        tracks[i].stop();
        tracks[i].enabled = false;
      }
      videoPreview.srcObject = null;
      videoPreview.src = '';
      const videoPreviewEl = document.getElementById(
        'video-preview'
      ) as HTMLVideoElement;
      if (videoPreviewEl) {
        videoPreviewEl.srcObject = null;
        videoPreviewEl.src = '';
      }
      setVideoPreview(null);
    }

    if (mediaRecordRef.current) {
      console.info('Clearing media record');
      mediaRecordRef.current.ondataavailable = null;
      mediaRecordRef.current.stop();
      mediaRecordRef.current = null;
    }
  }, [videoPreview, mediaRecordRef]);

  useEffect(() => {
    const fn = async () => {
      if (!videoPreview) {
        return;
      }

      const width = cameraWidth;
      const frameRate = 30;
      const bitrate = Math.round(0.2 * 1024 * 1024);

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { width, frameRate: frameRate },
          audio: true,
        });

        videoPreview.srcObject = stream;

        try {
          mediaRecordRef.current = new MediaRecorder(stream, {
            videoBitsPerSecond: bitrate,
            mimeType: 'video/webm',
          });

          /** alert('support: ' + MediaRecorder.isTypeSupported('video/mp4')); */
        } catch (e) {
          console.error(
            'MediaRecorder with specified options failed, falling back to default:',
            e
          );
          mediaRecordRef.current = new MediaRecorder(stream); // Fallback to default
        }

        mediaRecordRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            setIsRecordReady(true);

            const recordWebm = new Blob([event.data], {
              type: 'video/webm',
            });
            setVideoRecordDataWebm(recordWebm);

            const recordReplayBlob = new Blob([event.data], {
              type: 'video/mp4',
            });
            const url = window.webkitURL.createObjectURL(recordReplayBlob);
            setRecordReplaySrc(url);
          }
        };
      } catch (e) {
        console.error('Video camera is not available', e);
        alert(
          'Vidoe camera is not available. Please provide permissions and refresh the page'
        );
      }
    };
    fn();

    return () => {
      handleClearCameraConnection();
    };
  }, [
    cameraWidth,
    handleClearCameraConnection,
    mediaRecordRef,
    setIsRecordReady,
    setRecordReplaySrc,
    setVideoRecordDataWebm,
    videoPreview,
  ]);

  return (
    <video
      id="video-preview"
      playsInline
      muted
      autoPlay={true}
      width={cameraWidth + 'px'}
      style={{
        position: 'relative',
        zIndex: 5,
      }}
      ref={(e) => {
        setVideoPreview(e);
      }}
    ></video>
  );
}
