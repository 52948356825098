interface PreloaderProps {
  size?: string;
}

export default function Preloader({ size }: PreloaderProps) {
  return (
    <div
      data-testid="loading"
      style={{
        animation: 'spin 1s linear infinite',
        width: size ?? '50px',
        height: size ?? '50px',
        border: '4px solid #e2e8f0',
        borderTop: '4px solid #3498db',
        borderRadius: '50%',
      }}
    ></div>
  );
}
