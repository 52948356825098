import { Col, Flex, Row } from 'ui';
import Icon from 'components/Icon';
import { Text } from 'components/Text';
import React, { ReactNode, useEffect, useState } from 'react';
// @ts-ignore
import { ArrowDownIcon, ArrowUpIcon } from 'assets/Calendar';
import { initTimePickerValue } from '../constants';
import { TimePickerProps } from '../types';
import { showTwoDigits } from '../utils';
import { useIsMobile } from 'hooks/useBreakPoint';
import {
  TimePickerArrowTopMobileIcon,
  TimePickerArrowDownMobileIcon,
  // @ts-ignore
} from 'assets/svgComponents';

interface Props {
  setActiveTab: (tab: string) => void;
  state: TimePickerProps['value'];
  handleIncHours: () => void;
  handleDecHours: () => void;
  handleIncMin: () => void;
  handleDecMin: () => void;
  handleAmPm: () => void;
  width?: string;
  height?: string;
}

interface AmPmProps {
  children: ReactNode;
  onChange: () => void;
  active?: boolean;
}

function AmPm({ onChange, children, active }: AmPmProps) {
  const isMobile = useIsMobile();
  return (
    <Flex
      flexCenter
      width={isMobile ? '44px' : '30px'}
      height={isMobile ? '44px' : '30px'}
      borderRadius="6px"
      bg={active ? 'blue' : 'blueLight'}
      onClick={onChange}
      css="cursor: pointer"
      border={active ? '1px solid #4B5B98' : ''}
    >
      <Text
        color="white"
        fontSize={isMobile ? '24px' : '16px'}
        fontWeight="400"
        css="user-select: none"
      >
        {children}
      </Text>
    </Flex>
  );
}

export default function TimePickerMainView({
  onDateSelect,
  value = initTimePickerValue,
  setActiveTab,
  state,
  handleIncHours,
  handleDecHours,
  handleIncMin,
  handleDecMin,
  handleAmPm,
  width,
  height,
}: TimePickerProps & Props) {
  const isMobile = useIsMobile();

  return (
    <Flex
      width={width || 'auto'}
      minHeight={height || '94px'}
      gap="22px"
      justifyContent="center"
      alignItems="center"
    >
      <Col gap="22px">
        <Row width={isMobile ? '26px' : '20px'} justifyItems="center">
          <Icon onClick={handleIncHours} pathFillColor="blueLight">
            {isMobile ? <TimePickerArrowTopMobileIcon /> : <ArrowUpIcon />}
          </Icon>
          <Flex m={isMobile ? '29px 0px' : '12px 0px'}>
            <Text
              color="blue"
              fontWeight="400"
              fontSize={isMobile ? '24px' : '16px'}
              css={`
                cursor: pointer;
                user-select: none;
              `}
              onClick={() => setActiveTab('2')}
            >
              {state && showTwoDigits(state?.h)}
            </Text>
          </Flex>
          <Icon onClick={handleDecHours} pathFillColor="blueLight">
            {isMobile ? <TimePickerArrowDownMobileIcon /> : <ArrowDownIcon />}
          </Icon>
        </Row>
        <Row
          width={isMobile ? '26px' : '20px'}
          justifyItems="center"
          alignContent="center"
        >
          <Text fontWeight="500" fontSize="16px" css="user-select: none">
            :
          </Text>
        </Row>
        <Row width={isMobile ? '26px' : '20px'} justifyItems="center">
          <Icon onClick={handleIncMin} pathFillColor="blueLight">
            {isMobile ? <TimePickerArrowTopMobileIcon /> : <ArrowUpIcon />}
          </Icon>
          <Flex m={isMobile ? '29px 0px' : '12px 0px'}>
            <Text
              color="blue"
              fontWeight="400"
              fontSize={isMobile ? '24px' : '16px'}
              css={`
                cursor: pointer;
                user-select: none;
              `}
              onClick={() => setActiveTab('3')}
            >
              {state && showTwoDigits(state?.m)}
            </Text>
          </Flex>
          <Icon onClick={handleDecMin} pathFillColor="blueLight">
            {isMobile ? <TimePickerArrowDownMobileIcon /> : <ArrowDownIcon />}
          </Icon>
        </Row>
      </Col>
      {state && (
        <Row gap="10px" alignContent="center">
          <AmPm onChange={handleAmPm} active={state?.amPm === 'pm'}>
            PM
          </AmPm>
          <AmPm onChange={handleAmPm} active={state?.amPm !== 'pm'}>
            AM
          </AmPm>
        </Row>
      )}
    </Flex>
  );
}
