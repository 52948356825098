import React from 'react';
import { css } from 'styled-components';
import { LinkProps } from './Text.types';
import { LinkBase } from './Text.ui';
import { useIsMobile } from 'hooks/useBreakPoint';

function Link({
  children,
  to,
  ...restProps
}: React.PropsWithChildren<LinkProps>) {
  const isMobile = useIsMobile();
  return (
    <LinkBase
      kind="link"
      color="blueLink"
      href={to}
      {...restProps}
      onClick={(e) => {
        !isMobile && restProps?.onClick?.(e);
      }}
      onTouchEnd={(e) => {
        isMobile && restProps?.onClick?.(e);
      }}
      css={css`
        ${restProps.kind === 'icon' &&
        css`
          display: flex;
        `}
      `}
    >
      {children}
    </LinkBase>
  );
}

export default Link;
