import styled from 'styled-components';
import * as styles from './styles';
import { ColProps } from './Col.types';
import { Grid } from '../Grid';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  space,
  position,
  shadow,
  typography,
  compose,
} from 'styled-system';

const Col = styled(Grid)<ColProps>(
  (props) => styles.base(props),
  compose(
    grid,
    flexbox,
    layout,
    border,
    background,
    space,
    color,
    position,
    typography,
    shadow
  )
);

export default Col;
