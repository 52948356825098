import { useCallback, useLayoutEffect, useState } from 'react';
import { BreakpointsEnum, getBrowserHeight, getBrowserWidth } from 'utils';

export const mobileBreakpoint = 1130;
export const mobileWidth = 335;
export const desktopWidth = 568;

const dimensions = {
  xs: { more: 0, less: 360 },
  sm: { more: 360, less: 600 },
  md: { more: 600, less: 960 },
  lg: { more: 960, less: 1280 },
  xl: { more: 1280, less: 1920 },
};

const useBreakpoint = (breakpoint: BreakpointsEnum | number) => {
  const [isSize, setIsSize] = useState(false);

  const handleResize = useCallback(() => {
    const width = getBrowserWidth();

    if (Number.isInteger(breakpoint)) {
      setIsSize(width < Number(breakpoint as number) ? true : false);
      return;
    }

    if (
      // @ts-ignore
      width > dimensions?.[breakpoint as BreakpointsEnum]?.more &&
      // @ts-ignore
      width < dimensions?.[breakpoint as BreakpointsEnum]?.less
    ) {
      setIsSize(true);
      return;
    }

    setIsSize(false);
  }, [breakpoint]);

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return isSize;
};

export const useIsMobile = () => {
  return useBreakpoint(mobileBreakpoint);
};

export const useIsExtraSmall = () => {
  return useBreakpoint(BreakpointsEnum.Xs);
};

export const useIsHeight = (heightBreakpoint: number) => {
  const [isHeight, setIsHeight] = useState(false);

  const handleHeightResize = useCallback(() => {
    const height = getBrowserHeight();

    if (height < heightBreakpoint) {
      setIsHeight(true);
      return;
    }

    setIsHeight(false);
  }, [heightBreakpoint]);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleHeightResize);
    return () => {
      window.removeEventListener('resize', handleHeightResize);
    };
  }, [handleHeightResize]);

  return isHeight;
};

export default useBreakpoint;
