import { useContext } from 'react';
import { questionsAnswersContext } from './constants';

export const useQuestionsAnswers = () => {
  const context = useContext(questionsAnswersContext);
  if (context === undefined) {
    throw new Error(
      'useQuestionsAnswers must be used within a QuestionsAnswersProvider'
    );
  }
  return context;
};
