import { Form as FormBase } from 'antd';
import styled, { css } from 'styled-components';

export const Form = styled(FormBase)(
  () => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-form-item {
      margin-bottom: 10px;
    }

    label {
      flex-flow: row-reverse;
      column-gap: 2px;

      &.ant-form-item-required:before {
        /* content: unset !important; */
      }
      font-weight: 500;
      font-size: 14px;
    }
  `
);

export default Form;
