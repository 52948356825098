export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);

export const baseUrl = 'https://stage.123mgr.com/interview/api';

export const endpoints = {
  status: '/status/',
  questions: '/questions/',
  answersConfirmation: '/answers/',
  interviewPreview: '/interview-preview/',
};

export const routes = {
  root: '/',
  welcome: '/welcome',
  tutorial: '/tutorial',
  questions: '/questions',
  answers: '/answers',
  finish: '/finish',
  interviewPreview: '/interview-preview',
};

export const DEBUG = 'debug';
export const testInvitationKey = 'AAC25A';
