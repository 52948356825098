import React, { PropsWithChildren, ReactNode } from 'react';
import type { TextProps } from './Text.types';
import { TextBase } from './Text.ui';
import { Tooltip } from 'antd';

export function Text({
  children,
  as,
  color,
  maxChars,
  tooltipPlacement = 'top',
  showTooltip = false,
  toolTipLabel,
  ...restProps
}: PropsWithChildren<TextProps>) {
  const tunicateString = (str: string | ReactNode, maxChars: number) => {
    if (typeof str === 'string' && str?.length > maxChars) {
      return `${str.slice(0, maxChars)}...`;
    }

    return str;
  };

  if (!showTooltip) {
    return (
      <TextBase color={color} {...restProps} css={{ position: 'relative' }}>
        {maxChars ? tunicateString(children, maxChars) : children}
      </TextBase>
    );
  }

  return (
    <Tooltip zIndex={99999999} title={children} placement={tooltipPlacement}>
      <TextBase color={color} {...restProps} css={{ position: 'relative' }}>
        {maxChars ? tunicateString(children, maxChars) : children}
      </TextBase>
    </Tooltip>
  );
}

export default Text;
