import { BreakpointsProp } from 'globalTypes';
import { colors } from './colors';
import { typography } from './typography';

export { antdColorsConfig } from './colors';
export { antdTypographyConfig } from './typography';

const breakpoints: BreakpointsProp = ['40em', '52em', '64em', '80em'];

// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

const theme = {
  colors,
  typography,
  breakpoints,
};

export default theme;
