import React from 'react';
import { WithChild } from 'globalTypes';
import Box, { BoxProps } from '../Box';
import { css, useTheme } from 'styled-components';
import { brandedStyle } from './styles';
import { Spin } from 'antd';
import { mobileWidth, useIsMobile } from 'hooks/useBreakPoint';
import Row from '../Row';

export interface PageContainerProps extends BoxProps {
  branded?: boolean;
  loading?: boolean;
  width?: string;
}

const styles = css(
  ({ theme }) => css`
    position: relative;

    min-height: 100vh;

    @supports (max-height: 100dvh) {
      min-height: 100dvh;
    }

    .ant-spin {
      top: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
      background-color: ${theme?.colors?.gray}77;
      display: grid;
      justify-content: center;
      align-items: center;
      justify-items: center;
      align-content: center;
      pointer-events: none;
      transition: all 0.3;
    }

    .ant-spin.ant-spin-spinning {
      pointer-events: auto;
    }
  `
);

export default function PageContainer({
  width,
  children,
  branded,
  loading = false,
  ...restProps
}: WithChild<PageContainerProps>) {
  const theme = useTheme();
  const isMobile = useIsMobile();

  if (isMobile)
    return (
      <Row
        wide
        alignItems="flex-start"
        justifyContent="center"
        height="100%"
        maxWidth="100vw"
        bg={theme?.colors?.bg}
        css={styles}
        justifyItems="center"
        gridAutoRows="100%"
      >
        <Row
          wide
          alignItems="flex-start"
          justifyContent="center"
          height="100%"
          p="26px 0"
          minWidth="336px"
          width={width ?? `${mobileWidth}px`}
          maxWidth="100vw"
          {...restProps}
        >
          {children}
        </Row>
        {/* {<Spin spinning={loading} tip="Loading" size="default" />} */}
      </Row>
    );

  return (
    <Row
      wide
      alignItems="flex-start"
      justifyContent="center"
      height="100%"
      pt="30px"
      minHeight="100%"
      css={styles}
      justifyItems="center"
      gridAutoRows="100%"
    >
      <Row
        alignItems="flex-start"
        wide
        css={branded ? brandedStyle : ''}
        height="max-content"
        minHeight="720px"
        bg={theme?.colors?.bg}
        p="20px"
        boxShadow="0px 4px 4px rgba(93, 103, 160, 0.1),
            0px 4px 16px rgba(93, 103, 160, 0.08)"
        borderRadius="4px"
        width={width || { _: '1125px', md: '1125px' }}
        {...restProps}
      >
        {children}
      </Row>
      {/* {<Spin spinning={loading} tip="Loading" size="default" />} */}
    </Row>
  );
}
