import { Box, BoxProps } from 'ui';
import { WithChild } from 'globalTypes';
import React, { forwardRef } from 'react';
import { css } from 'styled-components';
import { curDayStyles } from './styles';
import { CurWeekBarProps } from './types';

export const CurWeekBar = forwardRef<HTMLDivElement, CurWeekBarProps>(
  ({ height }, ref) => {
    return (
      <Box height={height} className="currentWeekSelectionBar" ref={ref}>
        <div> </div>
      </Box>
    );
  }
);

CurWeekBar.displayName = 'CurWeekBar';

export const CurDay = ({ children, ...restProps }: WithChild<BoxProps>) => {
  return (
    <Box css={curDayStyles} margin={'0px !important'}>
      {children}
    </Box>
  );
};
