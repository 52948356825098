import 'styled-components';
import { Box, Flex } from 'ui';
import { INVITATION_KEY } from '@/contexts/QuestionsAnswersContext/constants';
import { useInterviewPreview } from '@/api/services';
import { useLocation } from 'react-router';
import { ArrowDownIcon, ArrowUpIcon } from '@/assets';
import { useCallback, useEffect, useRef, useState } from 'react';
import VideoPlayer from '@/components/VideoPlayer';
import { FlexProps } from 'components/layout/Flex/Flex.types';
import { Answer, InterviewPreviewOutput, Shift } from '@/api/typesApi';
import dummyAvatar from './assets/dummyAvatar.svg';
import { createGlobalStyle, css } from 'styled-components';
import { forwardRef } from 'react';
import colors from 'theme/colors';
import arrowDown from './assets/arrowDown.svg';

const GlobalStyle = createGlobalStyle(
  () => css`
    html,
    body,
    #root {
      overflow-x: hidden;
      overflow-y: hidden;
      position: relative;
      overflow: hidden !important;
      scroll-behavior: smooth;
    }
  `
);

const maxWidth = '335px';
const maxVideoWidth = '300px';

function InterviewPreview() {
  const [touchMoveDelta, setTouchMoveDelta] = useState(0);
  const [touchStartPoint, setTouchStartPoint] = useState(0);
  const { search } = useLocation();
  const invitation_key = new URLSearchParams(search)?.get?.(INVITATION_KEY);
  // const { data, isLoading, isError, error } = useInterviewPreview({
  //   invitation_key,
  // });
  const { data } = useInterviewPreview({
    invitation_key,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [curAnswer, setCurAnswer] = useState(0);

  const handleNextStep = useCallback(() => {
    const answersTopsEls = document.querySelectorAll('.answer-top');

    const nextAnswer = curAnswer + 1;
    const totalQuestion = data?.answers?.length;
    if (!answersTopsEls || totalQuestion === undefined) {
      return;
    }

    if (nextAnswer < totalQuestion) {
      answersTopsEls[nextAnswer].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setCurAnswer(nextAnswer);
    }
  }, [curAnswer, data?.answers?.length]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const answersTopsEls = document.querySelectorAll('.answer-top');

    const handleTouchStart = (e) => {
      // e.preventDefault();
      setTouchStartPoint(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
      // e.preventDefault();
      setTouchMoveDelta(e.touches[0].clientY - touchStartPoint);
    };

    const handleTouchEnd = () => {
      if (touchMoveDelta < 0) {
        handleNextStep();
      }

      if (touchMoveDelta > 0) {
        const prevAnswer = curAnswer - 1;
        if (prevAnswer >= 0) {
          answersTopsEls[prevAnswer].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          setCurAnswer(prevAnswer);
        }
      }

      setTouchMoveDelta(0);
      setTouchStartPoint(0);
    };

    document.addEventListener('touchstart', handleTouchStart, {
      passive: false,
    });
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd, { passive: false });

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [curAnswer, handleNextStep, touchMoveDelta, touchStartPoint]);

  return (
    <>
      <GlobalStyle />
      <Flex
        p="0px !important"
        width="100%"
        justifyContent="center"
        alignItems="center"
        backgroundColor={colors.grayInterviewPreviewBg}
        overflow="hidden"
        css={css`
          & {
            overflow: hidden;
          }
        `}
      >
        <Flex
          flexDirection="column"
          gap="0px"
          flexCenter
          color="black"
          overflow="hidden"
          css={css`
            & {
              overflow: hidden;
            }
          `}
        >
          {data?.answers
            ?.sort?.(sortAnswersByQuestionName)
            ?.map((answer, index) =>
              index === 0 ? (
                <FullHeightWrapper
                  onNextStep={handleNextStep}
                  totalQuestion={data?.answers?.length - 1}
                  curAnswer={curAnswer}
                  ref={wrapperRef}
                  key={index}
                >
                  <TopInfo data={data} />
                  <Introduction key={index} answer={answer} />
                </FullHeightWrapper>
              ) : (
                <FullHeightWrapper
                  onNextStep={handleNextStep}
                  curAnswer={curAnswer}
                  totalQuestion={data?.answers?.length - 1}
                  key={index}
                >
                  <Question key={index} answer={answer} data={data} />
                </FullHeightWrapper>
              )
            )}
        </Flex>
      </Flex>
    </>
  );
}

const FullHeightWrapper = forwardRef<
  HTMLDivElement,
  FlexProps & {
    curAnswer: number;
    totalQuestion: number;
    onNextStep?: () => void;
  }
>(({ children, curAnswer, totalQuestion, onNextStep, ...props }, ref) => {
  const isLast = curAnswer === totalQuestion;
  return (
    <Flex
      className="answer-top"
      ref={ref}
      height="100dvh"
      minHeight="100dvh"
      maxHeight="100dvh"
      maxWidth={maxWidth}
      alignItems="center"
      flexDirection="column"
      justifyContent="space-between"
      overflow="hidden"
      pb="0px"
      {...props}
    >
      <Flex
        overflow="hidden"
        flexDirection="column"
        alignItems="center"
        flexGrow={1}
      >
        {children}
      </Flex>
      <Flex
        mt="0pt"
        justifyContent="space-around"
        alignItems="center"
        onClick={onNextStep}
        width="100%"
        flexDirection="row"
      >
        {!isLast && (
          <img width="24px" height="24px" src={arrowDown} alt="swipe down" />
        )}
        <Flex
          fontSize="18px"
          fontWeight="600"
          color="#5B7188"
          flexDirection="column"
          alignItems="center"
        >
          {!isLast && <Flex>Swipe down to</Flex>}
          {!isLast && (
            <Flex mt="-5px">
              {curAnswer === 0 && 'to watch full interview'}
              {curAnswer === 1 && 'to 1st question'}
              {curAnswer === 2 && 'to 2nd question'}
              {curAnswer === 3 && 'to 3rd question'}
              {curAnswer === 4 && 'to 4th question'}
              {curAnswer === 5 && 'to 5th question'}
              {curAnswer === 6 && 'to 6th question'}
              {curAnswer === 7 && 'to 7th question'}
              {curAnswer === 8 && 'to 8th question'}
              {curAnswer === 9 && 'to 9th question'}
              {curAnswer === 10 && 'to 10th question'}
            </Flex>
          )}
          {isLast && <Flex>Done</Flex>}
        </Flex>
        {!isLast && (
          <img width="24px" height="24px" src={arrowDown} alt="swipe down" />
        )}
      </Flex>
    </Flex>
  );
});

export const HorizontalLine = (props?: Partial<FlexProps>) => (
  <Flex height="1px" backgroundColor="#E5E7F0" width="100%" {...props}></Flex>
);

const sortAnswersByQuestionName = (a: Answer, b: Answer) => {
  const aNum = parseInt(a.question_name.match(/\d+/)?.[0] || '0');
  const bNum = parseInt(b.question_name.match(/\d+/)?.[0] || '0');
  return aNum - bNum;
};

export const CollapsibleQuestionAnswer = ({
  children,
  ...restProps
}: {
  children?: string;
} & FlexProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Flex ref={ref} position="relative" flexDirection="row" zIndex={100}>
      {!isCollapsed && <Box height="59px"> </Box>}
      <Flex
        boxShadow="0px 4px 4px 0px rgba(20, 57, 128, 0.1)"
        borderRadius="10px"
        p="7px 10px"
        flexDirection="row"
        backgroundColor="white"
        position={isCollapsed ? 'relative' : 'absolute'}
        minWidth="335px"
        height={isCollapsed ? '54px' : 'max-content'}
        minHeight="54px"
        maxHeight={isCollapsed ? '54px' : '300px'}
        overflow="auto"
        zIndex={isCollapsed ? 0 : 1000}
        {...restProps}
      >
        <Flex
          flexGrow={1}
          fontSize="16px"
          fontWeight="700"
          lineHeight="20px"
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          {isCollapsed ? children?.slice(0, 50) + '...' : children}
        </Flex>

        <Flex
          width="20px"
          minWidth="20px"
          height="20px"
          top="16px"
          right="2px"
          position="relative"
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
          css="
        & > svg > path {
          fill: #143980 !important;
          opacity: 1 !important;
        }
        "
        >
          {isCollapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </Flex>
      </Flex>
    </Flex>
  );
};

const Question = ({
  answer,
  data,
}: {
  answer: Answer;
  data: InterviewPreviewOutput;
}) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
      }}
    >
      <Flex flexDirection="column" mt="0px">
        <Flex fontSize="20px" fontWeight="700" color="#143980" flexCenter>
          Question {answer?.question_name?.match?.(/\d+/)?.[0]} out of{' '}
          {data?.answers?.length - 1}
        </Flex>
        <Flex
          mt="5px"
          backgroundColor="white"
          borderRadius="10px"
          boxShadow="0px 4px 4px 0px rgba(20, 57, 128, 0.1)"
          p="7px 10px"
          fontSize="14px"
          width="335px"
          color="black"
          fontWeight="500"
        >
          {answer?.question_text}
        </Flex>
      </Flex>
      <Flex flexDirection="column" mt="5px">
        <Flex fontSize="20px" fontWeight="700" color="#143980" flexCenter>
          Answer
        </Flex>
        <CollapsibleQuestionAnswer mt="5px">
          {answer?.answer_transcription ?? ''}
        </CollapsibleQuestionAnswer>
        <Flex
          alignSelf="center"
          mt="10px"
          p="8px"
          backgroundColor="white"
          borderRadius="10px"
          boxShadow="0px 4px 4px 0px rgba(20, 57, 128, 0.1)"
          maxWidth={maxVideoWidth}
        >
          <VideoPlayer src={answer?.video_processed_url} controls />
        </Flex>
      </Flex>
    </div>
  );
};

const Introduction = ({ answer }: { answer?: Answer }) => {
  return (
    <Flex
      mt="10px"
      flexDirection="column"
      px="15px"
      pb="15px"
      pt="10px"
      backgroundColor="white"
      borderRadius="10px"
      boxShadow="0px 4px 4px 0px rgba(20, 57, 128, 0.1)"
      maxWidth={maxVideoWidth}
      gap="10px"
      justifyContent="center"
    >
      <Flex
        fontSize="20px"
        fontWeight="700"
        color="#143980"
        lineHeight="18px"
        flexCenter
      >
        Introduction
      </Flex>
      <Flex flexCenter>
        <VideoPlayer src={answer?.video_processed_url ?? ''} controls />
      </Flex>
    </Flex>
  );
};

const TopInfo = ({ data }: { data?: InterviewPreviewOutput }) => {
  const shifts: Record<Shift, string> = {
    am: 'am',
    pm: 'pm',
    overnight: 'overnight',
    flexible: 'flexible',
    late_closer: 'late_closer',
  };

  const isLongShiftTitle = [
    shifts.flexible,
    shifts.late_closer,
    shifts.overnight,
  ].includes(data?.shift as Shift);

  return (
    <Flex
      backgroundColor="white"
      color="black"
      mt="10px"
      boxShadow="0px 4px 4px 0px rgba(20, 57, 128, 0.1)"
      width="335px"
      flexDirection="column"
      borderRadius="4px"
      fontSize="18px"
      fontWeight="500"
    >
      <Flex
        height="110px"
        maxHeight="110px"
        px="13px"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={0}
        flexShrink={1}
      >
        <Flex flexDirection="column" gap="6px">
          <Flex
            width="50px"
            overflow="hidden"
            height="50px"
            borderRadius="50px"
            flexCenter
          >
            <img src={data?.lead_photo_url || dummyAvatar} alt="avatar" />
          </Flex>
          <Flex
            pr="5px"
            overflow="hidden"
            maxWidth="100px"
            fontWeight="500"
            css="& { white-space: nowrap; line-height: 19px; }"
            flexDirection="column"
            flexGrow={0}
            fontSize="20px"
            flexShrink={1}
          >
            <Flex css="& { letter-spacing: -1px !important;}">
              {data?.lead_name.split(' ')[0]}
            </Flex>
            <Flex css="& { letter-spacing: -1px !important;}">
              {data?.lead_name.split(' ')[1]}
            </Flex>
          </Flex>
        </Flex>

        <Flex
          flexDirection="column"
          fontSize="17px"
          flexGrow={1}
          borderRadius="10px"
          minWidth="150px"
          maxWidth="200px"
        >
          <Flex
            flexDirection="row"
            borderBottom={`1px solid #bfc3db`}
            px="10px"
          >
            <Flex
              alignItems="center"
              py="8px"
              flexGrow={1}
              css="& { white-space: nowrap; }"
              borderRight={`1px solid #bfc3db`}
              textAlign="left"
              overflow="hidden"
            >
              {data?.property}
            </Flex>
            <Flex
              alignItems="center"
              pl={isLongShiftTitle ? '2px' : '10px'}
              minWidth={isLongShiftTitle ? '65px' : '36px'}
              maxWidth={isLongShiftTitle ? '65px' : '36px'}
              width={isLongShiftTitle ? '65px' : '36px'}
              fontSize={isLongShiftTitle ? '13px' : '16px'}
              fontWeight="600"
              css="& {text-transform: uppercase; letter-spacing: -0px; }"
            >
              {data?.shift?.replace?.(/_/g, ' ')}
            </Flex>
          </Flex>
          <Flex flexDirection="row" px="10px">
            <Flex
              py="4px"
              alignItems="center"
              borderRight={`1px solid #bfc3db`}
              flexGrow={1}
              textAlign="left"
              overflow="hidden"
              css="& { white-space: nowrap; }"
            >
              {data?.position}
            </Flex>
            <Flex
              justifyContent={isLongShiftTitle ? 'center' : undefined}
              alignItems="center"
              py="4px"
              pl={isLongShiftTitle ? '2px' : '10px'}
              minWidth={isLongShiftTitle ? '65px' : '36px'}
              width={isLongShiftTitle ? '65px' : '36px'}
              maxWidth={isLongShiftTitle ? '65px' : '36px'}
            >
              ${Number(data?.pay_rate)}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InterviewPreview;
