import { flexCenter } from 'components/layout';
import { css } from 'styled-components';
import { CalendarProps, NavigationPosition } from './types';

const size = css`
  width: 32px;
  max-width: 32px;
  height: 32px;
  max-height: 32px;
`;

const margin = css`
  margin: 0px 8px 8px 0px;
`;

export const curDayStyles = css(
  ({ theme }) => css`
    ${margin}
    ${size};
    border-radius: 100%;
    position: relative;
    display: flex;
    background-color: ${theme.colors.blue};
    color: #fff;
    -webkit-text-fill-color: #fff;
    ${flexCenter};
    & > abbr {
      line-height: 0;
      position: absolute;
    }
  `
);

export const weekSelectStyle = css(
  ({ theme }) => css`
    .react-calendar__tile--active {
      background-color: transparent;
      color: rgba(17, 17, 17);
      -webkit-text-fill-color: rgba(17, 17, 17);
    }
  `
);

export const calendarStyles = css(
  ({ theme }) => css`
    .currentWeekSelectionBar {
      position: absolute;
      height: 34px;
      z-index: 1;
      width: 100%;
      left: 0;
      display: flex;
      ${flexCenter};

      & > div {
        opacity: 0.5;
        border-radius: 16px;
        background-color: ${theme.colors.blueLight};
        width: 100%;
        height: 100%;
        transform: scaleY(0.8);
      }
    }

    .react-calendar {
      position: relative;
      z-index: 2;
      user-select: none;
      -webkit-user-select: none; /* Safari */
    }

    .react-calendar__navigation {
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content max-content max-content;
      justify-content: space-between;
    }

    .weekNumberIndex {
      position: absolute;
      & > span {
        position: absolute;
        left: 16px;
        top: 11px;
      }
    }

    .react-calendar__month-view__days__day {
      background-color: transparent;
      border: none;
      -webkit-text-fill-color: rgba(17, 17, 17);
      -webkit-opacity: 1;
      ${size};
      ${margin}
      font-size: 16px;
    }

    .react-calendar__month-view__days__day:disabled {
      color: #ccc;
      -webkit-text-fill-color: #ccc;
    }

    .react-calendar__month-view__weekdays__weekday {
      ${size};
      ${margin}
      ${flexCenter};
      display: flex;
      font-size: 10px;
      abbr {
        text-decoration: none;
      }
    }

    .react-calendar__month-view__weekNumbers {
      display: block !important;
      & > div {
        display: flex;
        color: #ccc;
        font-size: 16px;
        ${size};
        ${margin}
        ${flexCenter};
      }
    }

    .react-calendar__tile {
      cursor: pointer;
      & > abbr {
        display: none;
      }
    }

    .react-calendar__tile--active {
      ${curDayStyles};
    }

    .aboveTextNav {
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      ${flexCenter};
    }
  `
);

export const navBarStyles = (position: NavigationPosition) =>
  css(
    ({ theme }) => css`
      .react-calendar__navigation {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: max-content max-content max-content;
        width: 100%;
        justify-content: space-between;
      }

      .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
        width: 247px;
        // height: 40px;
        display: flex;
        position: relative;
        ${position === 'bottom' &&
        'box-shadow: 0px 4px 4px rgba(93, 103, 160, 0.15), 0px 4px 16px rgba(93, 103, 160, 0.08);'}
        ${position === 'bottom' &&
        '-webkit-box-shadow: 0px 4px 4px rgba(93, 103, 160, 0.15), 0px 4px 16px rgba(93, 103, 160, 0.08);'}
        border-radius: 100px;
        font-size: 16px;
        font-weight: 500;
        ${flexCenter};
        color: ${theme.colors.fg};
      }

      .react-calendar__navigation__arrow {
        cursor: pointer;
        background-color: transparent;
        border: none;
      }

      .react-calendar__navigation__label {
        font-size: 16px;
        cursor: pointer;
        background-color: transparent;
        border: none;
        .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
          &,
          &[disabled] {
            background-color: transparent;
            border: none;
          }
        }
      }
    `
  );

export const navBarCompactStyles = ({ fullWidth }) =>
  css(
    ({ theme }) => css`
      ${navBarStyles('bottom')};
      .react-calendar__navigation {
        grid-auto-columns: ${fullWidth && '100%'};
      }

      .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
        max-width: ${fullWidth ? '100%' : '214px'};
        width: 100%;
      }
    `
  );
