import { useQuestionsAnswers } from '@/contexts/QuestionsAnswersContext';
import theme from 'theme';
import { Box } from 'ui';

interface DashProps {
  active?: boolean;
}

export function Dash({ active }: DashProps) {
  return (
    <Box
      width="50px"
      height="6px"
      borderRadius="10px"
      backgroundColor={active ? theme.colors.blueDeeeeep : theme.colors.blueWtf}
    ></Box>
  );
}

export interface TopNavDashesProps {
  totalDashes?: number;
  activeDashes?: number;
}

export default function TopNavDashes() {
  const { questionNumber, totalQuestions } = useQuestionsAnswers();

  return (
    <Box gap="6px" dxFlex flexDirection="row" justifyContent="center">
      {Array.from({ length: totalQuestions }, (_, i) => (
        <Dash key={i} active={i <= questionNumber} />
      ))}
    </Box>
  );
}
