import React, { useEffect } from 'react';

export default function useVideoControl(videoRef?: HTMLVideoElement | null) {
  const [isWatched, setIsWatched] = React.useState(false);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [curTime, setCurTime] = React.useState(0);
  const [curRef, setCurRef] = React.useState<HTMLVideoElement | null>(
    videoRef ?? null
  );
  const [videoDuration, setVideoDuration] = React.useState(0);
  const [isReady, setIsReady] = React.useState(false);

  const handlePause = () => {
    if (!curRef) {
      return;
    }

    curRef.pause();
  };

  const handlePlay = () => {
    if (!curRef) {
      return;
    }

    if (curRef.paused) {
      curRef.play();
      return;
    }

    handlePause();
  };

  const handleReset = () => {
    if (!curRef) {
      return;
    }

    curRef.pause();
    curRef.load();
  };

  useEffect(() => {
    if (videoRef) {
      setCurRef(videoRef);
    }
  }, [videoRef]);

  useEffect(() => {
    const video = curRef;

    const handleEnded = () => {
      setIsWatched(true);
    };

    const handlePlaying = () => {
      setIsPlaying(true);
    };
    const handlePause = () => {
      setIsPlaying(false);
    };
    const handleTimeUpdate = () => {
      if (!video) {
        return;
      }

      setCurTime(video.currentTime);
    };
    const handleLoadedMetadata = () => {
      if (!video) {
        return;
      }

      setVideoDuration(video.duration);
    };
    const handleCanPlay = () => {
      setIsReady(true);
    };

    video?.addEventListener('ended', handleEnded);
    video?.addEventListener('playing', handlePlaying);
    video?.addEventListener('pause', handlePause);
    video?.addEventListener('timeupdate', handleTimeUpdate);
    video?.addEventListener('loadedmetadata', handleLoadedMetadata);
    video?.addEventListener('canplay', handleCanPlay);

    return () => {
      video?.removeEventListener('ended', handleEnded);
      video?.removeEventListener('playing', handlePlaying);
      video?.removeEventListener('pause', handlePause);
      video?.removeEventListener('timeupdate', handleTimeUpdate);
      video?.removeEventListener('loadedmetadata', handleLoadedMetadata);
      video?.removeEventListener('canplay', handleCanPlay);
    };
  }, [curRef]);

  return {
    pause: handlePause,
    play: handlePlay,
    reset: handleReset,
    isWatched,
    isPlaying,
    setCurRef,
    curRef,
    isReady,
    curTime: curTime,
    duration: videoDuration,
  };
}
