import { Box, BoxProps, Flex, Text } from 'ui';
import { notification } from 'antd';
import { Employment } from 'globalTypes';
import React, { ReactNode } from 'react';
import defaultTheme from 'theme';

export const breakpoints = {
  0: 'xs',
  600: 'sm',
  960: 'md',
  1280: 'lg',
  1920: 'xl',
};

export enum BreakpointsEnum {
  Xs = 'xs',
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xm',
}

export function columnCell<T = any, R = any>(
  children?: (row: T, record: R) => ReactNode,
  boxProps?: BoxProps
) {
  return function render(row: any, record: any) {
    if (!row) return '';

    return children ? (
      children(row, record)
    ) : (
      <Text fontWeight="400">{row}</Text>
    );
  };
}

export const getInvoicePdfLink = (id: number, isMobile: boolean) => {
  let pdfHref = Urls['invoice:pdf']({ invoice_pk: id });
  const params = new URLSearchParams();
  if (window['managerSign']) {
    params.append('manager_sign', window['managerSign']);
    pdfHref += '?' + params.toString();
  }
  if (window['globalUrl'] && isMobile) {
    pdfHref =
      'https://docs.google.com/gview?embedded=true&url=' +
      window['globalUrl'] +
      pdfHref;
  }

  return pdfHref;
};

export const getInvoicePdfDriveLink = (link: string) => {
  return (
    'https://docs.google.com/gview?embedded=true&url=' +
    window['globalUrl'] +
    link
  );
};

export function truncate(str: string, n: number) {
  return str.length > n ? str.slice(0, n - 1) + '...' : str;
}

export function requiredVarsDeclared(...args: string[]) {
  for (const arg of args) {
    if (window?.[arg] === undefined) return false;
  }
  return true;
}

export function reload() {
  window.location.reload();
}

export function px(value?: number | string) {
  if (value === undefined) return 'unset';
  return typeof value === 'number' ? `${value}px` : value;
}

export const notify = (content: any) => {
  notification.info({
    placement: 'bottom',
    message: (
      <Box position="relative" p="0 20px" zIndex={999999999999999}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Box>
    ),
  });
};

export const pad = (num: number, zeros = 7) => {
  return num.toString().padStart(zeros, '0');
};

export const convertThemeColorNamesToHash = (
  colors: Record<string, any>,
  theme: typeof defaultTheme
) => {
  const convertedColors: Record<string, any> = {};
  for (const color in colors) {
    convertedColors[color] =
      colors[color] in theme['colors']
        ? theme.colors[colors[color]]
        : colors[color];
  }

  return convertedColors;
};

export function transformPositions(
  positions
): [{ label: string; value: any }] | [] {
  if (!positions) return [];
  return positions.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));
}

export function distinctPositions(employment: Employment[]) {
  if (!employment) return [];

  const res = [
    ...new Map(employment?.map((item) => [item?.position?.id, item])).values(),
  ];

  return res.map((item) => item.position);
}

export function getBrowserWidth() {
  return window.screen.width < window.innerWidth
    ? window.screen.width
    : window.innerWidth;
}

export function getBrowserHeight() {
  return window.screen.height < window.innerHeight
    ? window.screen.height
    : window.innerHeight;
}

export function parseNumericWithDecimal(str: string) {
  if (str === '.' || str === ',') return '';
  return str
    .replace(/,/g, '.')
    .replace(/[^.\d]/g, '')
    .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');
}

export function parseNumeric(str: string) {
  if (str === '.') return '';
  return str.replace(/\D/g, '');
}

export function trunicateDate(date: string) {
  if (date[date.length - 1] === 'Z') {
    return date.slice(0, date.length - 1);
  }
}

export function debounce(callback, time = 500) {
  let timer: ReturnType<typeof setTimeout> | undefined = undefined;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      // @ts-ignore
      callback.apply(this, args);
    }, time);
  };
}
