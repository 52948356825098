import { createGlobalStyle, css } from 'styled-components';

const AppGlobalStyle = createGlobalStyle(
  ({ theme }) => css`
    * {
      letter-spacing: 0.1px;
    }

    // disables all the errors exept firts one on the form item
    form
      div.ant-form-item-explain
      .ant-form-item-explain-error:not(:first-child) {
      display: none;
    }

    .highcharts-credits {
      display: none;
    }

    .noselect {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    }

    * {
      margin: 0;
      padding: 0;
      outline: none;
      box-sizing: border-box;
      &:focus,
      &:active {
        outline: none;
      }
      font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif !important;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    body {
      font-weight: 400;
      height: 100%;
      min-height: 100%;
      background-color: ${theme.colors.grayBg};
    }

    html {
      height: 100%;
      overflow-x: hidden;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    div {
      /* width */
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 6px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    ::selection {
      background-color: ${theme.colors.grayBg};
    }

    #root {
      height: 100%;
    }

    a {
      text-decoration: none;
    }

    .ant-select-dropdown {
      padding: 0px;
    }

    .ant-select-dropdown .ant-select-item-option-state {
      display: none !important;
    }

    .ant-select .ant-select-arrow {
      transition: transform 0.2s ease-in;
    }
    .ant-select.ant-select-open .ant-select-arrow {
      transform: rotate(180deg);
    }

    .ant-select-dropdown .ant-select-item {
      font-size: 14px;
      font-weight: 400;
    }

    .ant-select-dropdown .ant-select-item {
      font-size: 14px;
      font-weight: 400;
    }

    .ant-select-dropdown .ant-select-item-option-selected {
      background: rgba(86, 157, 229, 0.3) !important;
      font-weight: 400 !important;
    }

    ant-select-item
      ant-select-item-option
      ant-select-item-option-active
      ant-select-item-option-selected
      :where(.css-dev-only-do-not-override-1ribxyc).ant-spin-nested-loading
      > div
      > .ant-spin {
      max-height: calc(100%);
      opacity: 0.3;
    }

    .ant-modal {
      top: 40vh;
      & .ant-modal-content {
        border-radius: 4px;
        padding: 30px 20px 20px;
      }
    }

    .ant-drawer {
      &.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
      }

      .ant-drawer-content {
        border-radius: 8px 8px 0 0;
      }

      .ant-drawer-body {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }
      .ant-drawer-header {
        display: none;
      }
    }

    .date-picker-calendar {
      border: 0;
      box-shadow: 0px 4px 4px rgba(93, 103, 160, 0.15),
        0px 4px 16px rgba(93, 103, 160, 0.08);
    }

    .date-picker-calendar .react-datepicker__header {
      background-color: #fff;
      border-bottom: 0px;
    }

    .date-picker-calendar .react-datepicker__day {
      color: #111111;
      font-size: 10px;
    }

    .date-picker-calendar .react-datepicker__triangle {
      display: none;
    }

    .date-picker-calendar
      .react-calendar__tile
      react-calendar__month-view__days__day {
      font-weight: 300;
    }

    .date-picker-calendar .react-datepicker__current-month {
      font-weight: 500;
    }

    .date-picker-calendar .react-datepicker__day--disabled {
      color: #ccc;
    }

    .date-picker-calendar .react-datepicker__day:hover {
      border-radius: 100%;
      background-color: #2196f317;
    }

    .date-picker-calendar .react-datepicker__day--selected {
      border-radius: 100%;
      background-color: #569de5;
      color: #fff;
    }

    .date-picker-calendar .react-datepicker__navigation-icon--next::before {
      border-color: #569de5;
    }

    .date-picker-calendar .react-datepicker__navigation-icon--previous::before {
      border-color: #569de5;
    }

    .ant-drawer {
      &.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
      }

      .ant-drawer-content {
        border-radius: 8px 8px 0 0;
      }

      .ant-drawer-body {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }
      .ant-drawer-header {
        display: none;
      }
    }

    .ant-checkbox-wrapper {
      .ant-checkbox-checked {
        &:after {
          border: none !important;
        }
        .ant-checkbox-inner {
          background-color: #143980 !important;
          border-color: #143980 !important;
        }
      }
    }

    .date-picker-calendar .react-datepicker__year-text--disabled {
      color: #ccc !important;
    }

    .ant-checkbox-wrapper {
      .ant-checkbox-checked {
        &:after {
          border: none !important;
        }
        .ant-checkbox-inner {
          background-color: #143980 !important;
          border-color: #143980 !important;
        }
      }
    }

    .ant-checkbox .ant-checkbox-inner {
      width: 20px;
      height: 20px;
    }

    .ant-empty-normal {
      margin-block: 2px;
    }

    .ant-tooltip .ant-tooltip-inner {
      display: flex;
      align-items: center;
      padding: 4px 18px;
    }

    .ant-select.ant-select-in-form-item {
      border-radius: 4px;
    }

    .ant-form-item .ant-form-item-explain-error {
      font-size: 12px;
      color: rgba(235, 87, 87, 1);
    }
    .ant-notification {
      z-index: 999999999;
    }
  `
);

export default AppGlobalStyle;
