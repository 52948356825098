import { useGetQuestionsMutation } from '@/api/services';
import { routes } from '@/constants';
import { useQuestionsAnswers } from '@/contexts/QuestionsAnswersContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

export default function useGetQuestionWithAnswered() {
  const navigate = useNavigate();

  const {
    questionNumber,
    selectedLanguage,
    invitationKey,
    totalQuestions,
    onNextQuestion,
  } = useQuestionsAnswers();

  const { mutateAsync, data, isLoading, isError, error } =
    useGetQuestionsMutation();

  const questionName = data?.question?.name?.match(/\d+/)?.[0];
  const questionText = data?.question.text;
  const questionVideo = data?.question.video_url;
  const questionTopics = data?.question.topics.split(', ') || [];

  const isEng = selectedLanguage === 'english';

  const handleNextStep = () => {
    navigate('/answers');
  };

  useEffect(() => {
    const fn = async () => {
      try {
        await mutateAsync({
          invitation_key: invitationKey,
          question_num: questionNumber,
          language: selectedLanguage,
        });
      } catch (error) {
        if (questionNumber > totalQuestions) {
          navigate(routes.finish);
          return;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (error?.message?.match(/is_already_answered/)) {
          onNextQuestion();
          return;
        }
      }
    };

    fn();
  }, [
    invitationKey,
    mutateAsync,
    navigate,
    onNextQuestion,
    questionNumber,
    selectedLanguage,
    totalQuestions,
  ]);

  return {
    questionName,
    questionText,
    questionVideo,
    isEng,
    isLoading,
    isError,
    error,
    selectedLanguage,
    questionTopics,
    data,
    invitationKey,
    totalQuestions,
    questionNumber,
    handleNextStep,
    onNextQuestion,
  };
}
