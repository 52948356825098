const blue = '#569DE5';

export const colors = {
  grayLightSome: ['orange'],
  grayLighter: '#F4F2FF',
  grayLight: '#bfc3db',
  grayBg: '#ececec',
  gray: '#7f87b6',
  grayInterviewPreviewBg: '#D9E5F1',
  grayText: '#909090',
  yellow: '#FFE080',
  blueLight: '#BADCFF',
  blueLink: '#2D9CDB',
  blue,
  blueDisabled: '#B0CFF9',
  blueDeep: '#386CCF',
  blueDeeeeep: '#143980',
  blueWtf: '#D8DCFB',
  greenLink: '#48DB71',
  greenLight: '#A6D5BA',
  greenSummer: '#27AE60',
  green: '#219653',
  redLight: '#f7bcbc',
  red: '#eb5757',
  redCrimson: '#FE5F4A',
  purple: '#EACEFF',
  bg: 'white',
  fg: '#111111',
  textDarkBlue: '#143980',
  grayDisabled: '#b9b9b9',
  whitesmoke: '#f5f5f5',
  primary: blue,
  secondary: 'green',
  alternate: 'orange',
};

export const antdColorsConfig = {
  colorPrimary: colors.primary,
};

export default colors;
