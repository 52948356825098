import { Input as InputBase } from 'antd';

import styled, { css } from 'styled-components';

export const inputStyles = css(
  ({ theme }) => css`
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 0 50px white inset !important;
    border-color: ${theme.colors.gray}88;
    &:focus {
      box-shadow: 0 0 0 50px white inset !important;
      border-color: ${theme.colors.gray}88;
    }
    &:hover {
      border-color: ${theme.colors.gray}88;
    }
  `
);

export const Input = styled(InputBase)(
  ({ theme }) => css`
    ${inputStyles};
  `
);

export default Input;
