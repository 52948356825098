import colors from 'theme/colors';
import { Row, Button } from 'ui';

interface TryAgainPopupProps {
  isShowRetryConfirm: boolean;
  onHideRetryConfirm: () => void;
  onRecordStart: () => void;
  isEng?: boolean;
}

export default function TryAgainPopup({
  onHideRetryConfirm,
  isShowRetryConfirm,
  onRecordStart,
  isEng,
}: TryAgainPopupProps) {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          backgroundColor: isShowRetryConfirm
            ? 'rgba(0, 0, 0, 0.5)'
            : 'transparent',
          zIndex: 100,
          pointerEvents: isShowRetryConfirm ? 'auto' : 'none',
        }}
        onClick={() => {
          onHideRetryConfirm();
        }}
      ></div>
      <Row
        gap="10px"
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        backgroundColor="white"
        flexCenter
        height="146px"
        style={{
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          borderBottomLeftRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
          transform: !isShowRetryConfirm ? 'translateY(146px)' : 'none',
          transition: 'transform 0.5s',
          zIndex: 200,
        }}
      >
        <Button
          maxWidth="332px"
          kind="large"
          onClick={() => {
            onHideRetryConfirm();
            onRecordStart();
          }}
        >
          {isEng ? 'TRY AGAIN' : 'INTENTAR OTRA VEZ'}?
        </Button>
        <Button
          maxWidth="332px"
          kind="large"
          backgroundColor={colors.redCrimson}
          onClick={() => {
            onHideRetryConfirm();
          }}
        >
          {isEng ? 'CANCEL' : 'Cancelar'}?
        </Button>
      </Row>
    </>
  );
}
