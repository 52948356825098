import { useState, useRef, useEffect } from 'react';

interface CountdownProps {
  initTime: number;
  onTimeout?: () => void;
}

export default function useCountdown({ initTime, onTimeout }: CountdownProps) {
  const [time, setTime] = useState(initTime);
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const startCountdown = () => {
    if (isRunning) {
      return;
    }
    setIsRunning(true);
    intervalRef.current = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 0) {
          stopCountdown();
          onTimeout?.();
          clearTimeout();
          return initTime;
        }

        return prevTime - 1;
      });
    }, 1000);
  };

  const stopCountdown = () => {
    setIsRunning(false);
    clearTimeout();
  };

  const reset = () => {
    setTime(initTime);
  };

  const clearTimeout = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout();
    };
  }, []);

  return { time, startCountdown, stop: stopCountdown, reset } as const;
}
