import styled, { css } from 'styled-components';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  space,
  position,
  shadow,
  typography,
  compose,
} from 'styled-system';
import { BoxProps } from './Box.types';
import React, { forwardRef, PropsWithChildren } from 'react';
import * as styles from '../styles';

const BoxBase = styled.div.withConfig<BoxProps>({
  shouldForwardProp: (prop, defValidFn) => defValidFn(prop),
})<BoxProps>(
  ({ transform }) =>
    transform &&
    css`
      transform: ${transform};
    `,
  ({ flexCenter }) => flexCenter && styles.flexCenter,
  ({ dxFlex }) =>
    dxFlex &&
    css`
      display: flex;
    `,
  compose(
    grid,
    flexbox,
    layout,
    border,
    background,
    space,
    color,
    position,
    typography,
    shadow
  )
);

const Box = forwardRef<HTMLDivElement, PropsWithChildren<BoxProps>>(
  ({ children, gap, ...restProps }, ref) => {
    return (
      <BoxBase gridGap={gap} ref={ref} {...restProps}>
        {children}
      </BoxBase>
    );
  }
);

Box.displayName = 'Box';

export default Box;
