import 'styled-components';
import { Row } from 'ui';
import VideoPlayer from '@/components/VideoPlayer';
import useVideoControl from '@/hooks/useVideoControl';
import ButtonFillEffect from '@/components/ButtonFillEffect';
import LayoutPageMobile from '@/components/LayoutPageMobile';
import { isLocal } from '@/utils';
import TopTextBlock from '@/components/TopTextBlock';

interface IntroductionProps {
  handleNextStep: () => void;
  isLoading: boolean;
  isError: boolean;
  error: string | Error | unknown;
  isEng: boolean;
  questionVideo?: string;
}

export default function Introduction({
  error,
  isError,
  handleNextStep,
  isLoading,
  isEng,
  questionVideo,
}: IntroductionProps) {
  const { setCurRef, curRef, isWatched } = useVideoControl();

  return (
    <LayoutPageMobile
      loading={isLoading}
      error={!!isError}
      errorInfo={(error as Error)?.message ?? error}
      header={
        <Row flexCenter>
          <TopTextBlock title={isEng ? 'Introduction' : 'Introducción'} />
        </Row>
      }
      footer={
        <Row
          py="20px"
          borderTopLeftRadius="4px"
          borderTopRightRadius="4px"
          width="100%"
          flexCenter
          backgroundColor="white"
        >
          <ButtonFillEffect
            videoRef={curRef}
            onClick={() => {
              if (isLocal || isWatched) {
                handleNextStep();
                return;
              }
            }}
          >
            {isEng ? 'BEGIN INTRODUCTION' : 'COMENZAR INTRODUCCIÓN'}
          </ButtonFillEffect>
        </Row>
      }
    >
      <Row flexCenter>
        <Row mt="20px">
          <VideoPlayer autoPlay src={questionVideo} onSetCurRef={setCurRef} />
        </Row>
      </Row>
    </LayoutPageMobile>
  );
}
