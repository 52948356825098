import styled, { css } from 'styled-components';
import * as styles from './styles';
import { Grid } from '../Grid';
import { RowProps } from './Row.types';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  space,
  position,
  shadow,
  typography,
  compose,
} from 'styled-system';

const Row = styled(Grid)<RowProps>(
  () => styles.base,
  ({ wide }) =>
    wide &&
    css`
      width: 100%;
      grid-auto-columns: 100%;
    `,
  ({ flexCenter }) =>
    flexCenter &&
    css`
      justify-items: 'center';
      justify-content: initial;
      align-content: 'center';
      align-items: initial;
    `,
  ({ verticalCenter }) =>
    verticalCenter &&
    css`
      justify-items: 'center';
    `,
  compose(
    grid,
    flexbox,
    layout,
    border,
    background,
    space,
    color,
    position,
    typography,
    shadow
  )
);

export default Row;
